// digicore colors

// primary

$light-primary: #054371;
$light-primary-contrast: #ffffff;

$dark-primary: #015799;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #f69224;
$light-accent-contrast: #ffffff;

$dark-accent: #f69224;
$dark-accent-contrast: #ffffff;

// common

$page-background: #0543712f;
