// font variables

$large-font-size: 26px;
$heading-font-size: 17px;
$body-font-size: 15px;
$caption-font-size: 13px;

$light-font-weight: 300;
$normal-font-weight: 400;
$medium-font-weight: 500;
$bold-font-weight: 600;

$font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

@font-face {
  font-family: $font-family;
  src: url('/src/fonts/digicore/Nexa-Light.woff2') format('woff2'), url('/assets/digicore/fonts/Nexa-Light.woff') format('woff');
  font-weight: $light-font-weight;
  font-style: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: $font-family;
  src: url('/src/fonts/digicore/Nexa-Regular.woff2') format('woff2'), url('/assets/digicore/fonts/Nexa-Regular.woff') format('woff');
  font-weight: $normal-font-weight;
  font-style: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: $font-family;
  src: url('/src/fonts/digicore/Nexa-Bold.woff2') format('woff2'), url('/assets/digicore/fonts/Nexa-Bold.woff') format('woff');
  font-weight: $bold-font-weight;
  font-style: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
}
